import { Config } from '../configs';
import { postData } from './httpClient/httpClient';

export const createDeliveryPromise = async ({
  deliveryRestaurantId,
  restaurantId,
  venueId,
  street,
  city,
  zipCode,
  isDeliverNow,
  deliveryDate,
  orderPrice,
}) => {
  try {
    if (!street || !city /*|| !zipCode */) {
      return { error: { errorCode: 'unknown' } };
    }

    const response = await postData(`${Config.TRACKING.URL}/api/delivery/promise`, {
      deliveryRestaurantId,
      restaurantId,
      venueId,
      street,
      city,
      postCode: zipCode,
      isDeliverNow,
      deliveryDate,
      orderPrice,
    });

    if (!response.error && !response.response) {
      return { error: { errorCode: 'unknown' } };
    }

    return { error: response.error, response: response.response };
  } catch (e) {
    if (Config.SHOW_LOGS) {
      console.log('Error while trying to get delivery promise', e);
    }
  }

  return { error: { errorCode: 'unknown' } };
};
