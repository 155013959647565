import { useState, useEffect, useRef } from 'react';
import { useMutationObservable } from './useMutationObservable';

export const usePayntPayButtonWrapper = ({
  wrapperId,
  payButtonClass,
  disabled,
  isValidOnSubmit,
  updatePaymentBeforeSubmit,
}) => {
  const [readyRefresher, setReadyRefresher] = useState(0);
  const [readyProcessedRefresher, setReadyProcessedRefresher] = useState(0);
  const [buttonPressRefresher, setButtonPressRefresher] = useState(0);

  // Discovers when button is rendered
  useMutationObservable(global.document.getElementById('paynt-widget-container'), () => {
    const payButton = global.document.querySelector(`.${payButtonClass}`);
    if (payButton) {
      setReadyRefresher((prev) => prev + 1);
    }
  });

  const buttonEventListenerRef = useRef({
    func: () => {
      setButtonPressRefresher((prev) => prev + 1);
    },
  });

  useEffect(() => {
    const gpayWrapper = global.document.getElementById(wrapperId);
    if (!gpayWrapper) {
      return;
    }

    if (disabled) {
      gpayWrapper.classList.add('disabled');
    } else {
      gpayWrapper.classList.remove('disabled');
    }
  }, [disabled, readyProcessedRefresher]);

  useEffect(() => {
    if (readyRefresher <= 0) {
      return () => {};
    }

    const gpayButton = global.document.querySelector(`.${payButtonClass}`);
    if (!gpayButton) {
      return () => {};
    }

    const { parentNode } = gpayButton;

    parentNode.addEventListener('click', buttonEventListenerRef.current.func);
    parentNode.setAttribute('id', wrapperId);

    gpayButton.disabled = true;
    setReadyProcessedRefresher((prev) => prev + 1);

    return () => {
      parentNode.removeEventListener('click', buttonEventListenerRef.current.func);
    };
  }, [readyRefresher]);

  useEffect(() => {
    const clickButtonAsync = async () => {
      if (buttonPressRefresher <= 0) {
        return;
      }

      if (!isValidOnSubmit()) {
        return;
      }

      const gpayButton = global.document.querySelector(`.${payButtonClass}`);
      if (!gpayButton) {
        return;
      }

      const dataUpdated = await updatePaymentBeforeSubmit({
        externalPaymentId: global.document.getElementById('paynt-payment-id').dataset.payment,
      });
      if (!dataUpdated) {
        return;
      }

      const { parentNode } = gpayButton;

      parentNode.removeEventListener('click', buttonEventListenerRef.current.func);
      gpayButton.disabled = false;
      gpayButton.click();
    };

    clickButtonAsync();
  }, [buttonPressRefresher]);

  return {
    onFormCancel: () => {
      setReadyRefresher((prev) => prev + 1);
    },
  };
};
