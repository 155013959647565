import { code, iam } from 'codemash';
import { Config } from '../configs';
import { makeId } from '../utils/stringHelper';
import { mapApiToDataGuestUser } from './mappers/auth';
import { manageApiUnavailableError, manageFunctionResponse } from './error/errors';

export const getUser = async ({ token }) => {
  try {
    const response = await iam.getProfile({
      secretKey: token,
    });

    return {
      user: mapApiToDataGuestUser({ user: response }),
    };
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to get user', e);
    }
  }

  return {};
};

export const registerGuest = async () => {
  try {
    const response = await iam.registerGuest({
      displayName: makeId(10),
    });

    return {
      token: response.bearerToken,
      user: mapApiToDataGuestUser({ user: response.result }),
    };
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to register guest user', e);
    }
  }

  return {};
};

export const testFeaturesLogin = async ({ accessToken, restaurantId }) => {
  try {
    const response = await code.executeFunction({
      functionId: Config.DATA.FUNCTIONS.CHECK_TEST_ACCESS_TOKEN,
      data: {
        accessToken,
        restaurantId,
      },
    });

    const managedResponse = manageFunctionResponse({
      response,
    });

    if (managedResponse.isError) {
      if (!managedResponse.handled) {
        //
      }
    } else {
      return managedResponse;
    }

    return null;
  } catch (e) {
    if (Config.SHOW_LOGS) {
      console.log('Error while trying to login', e);
    }
  }

  return null;
};
