import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { getTranslatedData } from '../../../../localization';
import { useCurrency } from '../../../../hooks/useCurrency';
import { formFileUrlFromFileArrayWithExternalResized } from '../../../../utils/fileHelper';
import { discardProductDiscount, formatProductForCart } from '../../../../services/cartService';
import { Context as RestaurantContext } from '../../../../context/restaurant';
import Variation from './variation';
import AddCartButton from './addCartButton';
import spicyIcon from '../../../../assets/images/icons/spicy-icon.png';
import veganIcon from '../../../../assets/images/icons/vegan-icon.png';
import { roundDecimal } from '../../../../utils/roundingHelpers';
import ConfirmationModal from '../../../../components/modal/confirmationModal';
import ProductNewTag from '../product/components/productNewTag';
import ProductPopularTag from '../product/components/productPopularTag';
import Loader from '../../../../components/misc/loader';

const ProductModal = ({
  product,
  onAdd,
  refresher,
  onClose,
  discountMenuService,
  onlyDisplay,
  needConfirmAge,
  restrictionsAge,
  onAgeConfirmed,
  roundedCorners,
  scrollable,
}) => {
  const { t } = useTranslation();
  const { currencySymbol } = useCurrency();

  const { getProductsVariations } = useContext(RestaurantContext);

  const [isLoadingVariations, setIsLoadingVariations] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [variationOptions, setVariationOptions] = useState({});
  const [ageConfirmationVisible, setAgeConfirmationVisible] = useState(false);

  const initProductVariations = async () => {
    if (!product || product.variationsLoaded) {
      return;
    }

    setIsLoadingVariations(true);
    await getProductsVariations({ productIds: [product.id] });
    setIsLoadingVariations(false);
  };

  useEffect(() => {
    if (refresher) {
      initProductVariations();
      setQuantity(1);
      setVariationOptions({});
    }
  }, [refresher]);

  const areAllRequiredAdded = useMemo(() => {
    if (!product) {
      return false;
    }

    let totalRequired = 0;
    let totalSelectedRequired = 0;

    let totalNeedMoreThanMin = 0;
    let totalMoreThanMin = 0;

    if (product.variations) {
      product.variations.forEach((variation) => {
        if (variation.required) {
          totalRequired += 1;
          if (variationOptions[variation.id]) {
            totalSelectedRequired += 1;
          }
        }

        if (variation.type === 'multi_selection_max' && variation.minOfMulti > 0) {
          totalNeedMoreThanMin += 1;
          if (
            variationOptions[variation.id] &&
            variationOptions[variation.id].length >= variation.minOfMulti
          ) {
            totalMoreThanMin += 1;
          }
        }
      });
    }

    return totalRequired === totalSelectedRequired && totalNeedMoreThanMin <= totalMoreThanMin;
  }, [variationOptions, product]);

  if (!product) {
    return null;
  }

  const onAddProductToCart = () => {
    const productWithoutDiscount = discardProductDiscount({ product });
    const formedProduct = formatProductForCart({
      product: productWithoutDiscount,
      quantity,
      variationOptions,
    });

    onAdd(formedProduct);
  };

  const renderSpicyIcon = (spicyLevel) => {
    if (!spicyLevel) {
      return null;
    }

    const image = <img src={spicyIcon} alt="spicy" className="inline-block" />;

    return (
      <div className="inline-block">
        {spicyLevel > 0 && image}
        {spicyLevel > 1 && image}
        {spicyLevel > 2 && image}
      </div>
    );
  };

  const renderVeganIcon = (isVegan) => {
    if (!isVegan) {
      return null;
    }

    return (
      <div className="inline-block relative top-[2px]">
        <img src={veganIcon} alt="vegan" />
      </div>
    );
  };

  const renderImage = () => {
    const imageUrl =
      // If has cover
      formFileUrlFromFileArrayWithExternalResized(product.coverImage, product.coverImageResized) ||
      // Else if has big list image resize
      formFileUrlFromFileArrayWithExternalResized(
        product.listImage,
        product.listImageResizedBig,
        true,
      );

    if (!imageUrl) {
      return null;
    }

    return (
      <div
        className="w-full h-64 bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
    );
  };

  const renderDiscountTag = (discount, isRelative) => {
    if (!discount) {
      return null;
    }

    return (
      <div
        className={`border-[1px] border-white bg-white px-2 py-[1px]
        font-primaryMedium text-primary text-sm rounded-[20px] inline-block
        ${isRelative ? '' : 'absolute -bottom-3 right-4'}
        shadow-md`}
      >
        -{Math.abs(discount)}%
      </div>
    );
  };

  const calculatePrice = () => {
    let additionPrice = 0;
    if (variationOptions) {
      Object.entries(variationOptions).forEach(([variationId, itemIdOrIds]) => {
        const variationData = product.variations
          ? product.variations.find((x) => x.id === variationId)
          : null;
        const variationItemsData = variationData ? variationData.items : null;
        if (!variationItemsData) {
          return;
        }

        if (Array.isArray(itemIdOrIds)) {
          itemIdOrIds.forEach((itemId) => {
            const data = variationItemsData.find((x) => x.id === itemId);
            if (data) {
              additionPrice += data.price || 0;
            }
          });
        } else {
          const data = variationItemsData.find((x) => x.id === itemIdOrIds);
          if (data) {
            additionPrice += data.price || 0;
          }
        }
      });
    }

    // Temp parameter to recalculate from full price
    const productWithoutDiscount = discardProductDiscount({ product });

    const formedCartItem = formatProductForCart({
      product: productWithoutDiscount,
      quantity,
      variationOptions,
    });

    // Find full discount with variations
    const appliedDiscountsWithVariations = discountMenuService.applyFormedCartItemDiscount({
      cartItem: formedCartItem,
    });

    // Reduce discount to single sum
    if (appliedDiscountsWithVariations && appliedDiscountsWithVariations.length > 0) {
      appliedDiscountsWithVariations.forEach((calculatedDiscount) => {
        formedCartItem.discountTotal += calculatedDiscount.sum;
      });

      formedCartItem.discountTotal = roundDecimal(formedCartItem.discountTotal);
    }

    const calculatedFinalPrice =
      quantity * (product.priceBefore || product.price || 0) +
      quantity * additionPrice -
      (formedCartItem.discountTotal || 0);

    return calculatedFinalPrice || 0;
  };

  const totalPrice = calculatePrice();

  const renderVariations = () => {
    if (isLoadingVariations) {
      return (
        <div className="py-4">
          <Loader small />
        </div>
      );
    }

    if (!product.variations || product.variations.length === 0) {
      return null;
    }

    return (
      <div className="mt-5">
        {product.variations.map((variation) => {
          if (!variation.items || variation.items.length === 0) {
            return null;
          }

          return (
            <Variation
              key={variation.id}
              variation={variation}
              onItemsChange={(val) => {
                setVariationOptions((prev) => {
                  return { ...prev, [variation.id]: val };
                });
              }}
              onlyDisplay={onlyDisplay}
            />
          );
        })}
      </div>
    );
  };

  const renderClose = () => {
    return (
      <div
        role="button"
        onClick={onClose}
        className="w-7 h-7 bg-white shadow-md z-30 rounded-full flex justify-center items-center"
      >
        <MdClose size={20} className="text-primary" />
      </div>
    );
  };

  const imageObj = renderImage();

  return (
    <div
      className={`${
        scrollable ? 'overflow-y-auto overflow-x-hidden scrollbar-v' : 'overflow-hidden'
      } ${roundedCorners ? 'rounded-3xl' : ''}`}
    >
      {!!imageObj && (
        <div className="relative">
          {imageObj}
          <div className="relative">{renderDiscountTag(product.discountPercent)}</div>
          <div className="absolute top-4 right-4">{renderClose()}</div>
        </div>
      )}
      {!imageObj && <div className="flex justify-end mt-4 mr-4">{renderClose()}</div>}
      <div className="pb-6" />
      <div className="px-5">
        <div className="mb-4">
          <div className="flex flex-row justify-between items-start">
            <div className="font-primaryMedium text-xl text-primary mb-2 inline-block mr-2">
              <div className="inline-block mr-2">{getTranslatedData(product.name) || '-'}</div>
              <div className="inline-block relative -top-[2px]">
                {renderSpicyIcon(product.spicyLevel)}
                {!!product.spicyLevel && product.isVegetarian && (
                  <div className="ml-1 inline-block" />
                )}
                {renderVeganIcon(product.isVegetarian)}
              </div>
            </div>

            <div className="flex flex-row flex-wrap items-center justify-end relative">
              {!!product.priceBefore && product.price !== product.priceBefore && (
                <p className="font-primaryMedium text-bland text-base line-through whitespace-nowrap">
                  {(product.priceBefore || 0).toFixed(2)} {currencySymbol}
                </p>
              )}
              {!!product.price && (
                <p className="font-primaryMedium text-primary text-xl ml-2 whitespace-nowrap">
                  {(product.price || 0).toFixed(2)} {currencySymbol}
                </p>
              )}
              {!imageObj && (
                <div className="absolute -bottom-7 right-0">
                  {renderDiscountTag(product.discountPercent, true)}
                </div>
              )}
            </div>
          </div>

          {(product.isPopular || product.isNew) && (
            <div className="flex flex-row flex-wrap mb-2">
              <ProductPopularTag isPopular={product.isPopular} />
              {product.isPopular && product.isNew && <div className="inline-block mr-1" />}
              <ProductNewTag isNew={product.isNew} />
            </div>
          )}

          {!!getTranslatedData(product.description) && (
            <div className="font-primaryMedium text-base text-bland">
              {getTranslatedData(product.description)}
            </div>
          )}

          {renderVariations()}
        </div>
      </div>
      {onlyDisplay && <div className="pb-10" />}
      {!onlyDisplay && (
        <AddCartButton
          price={totalPrice}
          quantity={quantity}
          onAdd={() => {
            if (product.isAgeRestricted && needConfirmAge) {
              setAgeConfirmationVisible(true);
            } else {
              onAddProductToCart();
            }
          }}
          onChangeQuantity={setQuantity}
          min={1}
          max={99}
          disabled={!areAllRequiredAdded || totalPrice <= 0}
        />
      )}
      <ConfirmationModal
        open={ageConfirmationVisible}
        title={t('products.ageConfirmationTitle', { ageNumber: restrictionsAge || 18 })}
        text={t('products.ageConfirmationDescription')}
        onClose={() => {
          setAgeConfirmationVisible(false);
        }}
        onConfirm={() => {
          onAddProductToCart();
          setAgeConfirmationVisible(false);
          onAgeConfirmed();
        }}
      />
    </div>
  );
};

export default ProductModal;
