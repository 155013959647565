import { db } from 'codemash';
import { Config } from '../configs';
import { mapDeliverySettings, mapDeliveryRestaurants } from './mappers/delivery';
import { manageApiUnavailableError } from './error/errors';
import {
  mapApiToDataRestaurantEvents,
  mapApiToDataRestaurantPromotions,
} from './mappers/restaurant';

export const getComapnyRestaurantsBySlug = async ({ companyHost }) => {
  try {
    if (!companyHost) {
      return {};
    }

    const response = await db.executeAggregate({
      id: Config.DATA.AGGREGATES.GET_COMPANY_RESTAURANTS_BY_HOST,
      collectionName: Config.DATA.COLLECTIONS.DELIVERY_SETTINGS,
      database: Config.CODEMASH.DB_STORAGE_ID,
      tokens: {
        host: companyHost,
      },
    });

    if (response && response.length > 0) {
      return {
        deliverySettings: mapDeliverySettings({ settings: response[0].delivery_settings }),
        restaurants: mapDeliveryRestaurants({ restaurants: response[0].restaurants }),
      };
    }
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to get table by qr code', e);
    }
  }

  return {};
};

export const getRestaurantEvents = async ({ restaurantId }) => {
  try {
    const response = await db.getRecords({
      collectionName: Config.DATA.COLLECTIONS.RESTAURANT_EVENTS,
      database: Config.CODEMASH.DB_STORAGE_ID,
      filter: {
        restaurants: { $eq: { $oid: restaurantId } },
        hidden: false,
      },
      pageNumber: 0,
      pageSize: 100,
    });

    const mappedEvents = mapApiToDataRestaurantEvents({
      events: (response || {}).result,
    });

    return {
      mappedEvents,
    };
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to get events', e);
    }
  }

  return {};
};

export const getRestaurantPromotions = async ({ restaurantId }) => {
  try {
    const response = await db.getRecords({
      collectionName: Config.DATA.COLLECTIONS.RESTAURANT_PROMOTIONS,
      database: Config.CODEMASH.DB_STORAGE_ID,
      filter: {
        restaurants: { $eq: { $oid: restaurantId } },
        hidden: false,
      },
      pageNumber: 0,
      pageSize: 100,
    });

    const mappedPromotions = mapApiToDataRestaurantPromotions({
      promotions: (response || {}).result,
    });

    return {
      mappedPromotions,
    };
  } catch (e) {
    manageApiUnavailableError(e);

    if (Config.SHOW_LOGS) {
      console.log('Error while trying to get promotions', e);
    }
  }

  return {};
};
