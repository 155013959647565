import { Config } from '../configs';

export function formFileUrlFromDbFile(dbFile, getOriginal) {
  if (!dbFile) {
    return null;
  }
  if (dbFile.optimizations && dbFile.optimizations.length > 0 && !getOriginal) {
    const firstOptimization = dbFile.optimizations[0];
    return `https://cm-${Config.CODEMASH.FILE_ACCOUNT_ID}.s3.eu-central-1.amazonaws.com/${firstOptimization.directory}/${firstOptimization.fileName}`;
  }

  return `https://cm-${Config.CODEMASH.FILE_ACCOUNT_ID}.s3.eu-central-1.amazonaws.com/${dbFile.directory}/${dbFile.fileName}`;
}

export function formFileUrlFromFileArray(fileArray) {
  if (fileArray === null) {
    return fileArray;
  }

  if (typeof fileArray === 'string') {
    return fileArray;
  }

  if (!fileArray || fileArray.length === 0) {
    return null;
  }
  const dbFile = fileArray[0];

  return formFileUrlFromDbFile(dbFile);
}

export function getFileSizeFromBase64(base64) {
  if (!base64) {
    return 0;
  }

  const baseLength = base64.length;
  const numberOfPaddingCharacters = base64[baseLength - 2] === '=' ? 2 : 1;

  const bytesSize = 3 * (baseLength / 4) - numberOfPaddingCharacters;
  return bytesSize;
}

export function convertBytesToMb(bytes) {
  return bytes / (1024 * 1024);
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line prefer-template, prefer-exponentiation-operator, no-restricted-properties
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function formFileUrlFromFileArrayWithExternalResized(
  originalFileArray,
  resizedFileArray,
  dontShowOriginal,
) {
  if (!originalFileArray || originalFileArray.length === 0) {
    return null;
  }

  if (!resizedFileArray || resizedFileArray.length === 0) {
    if (dontShowOriginal) {
      return null;
    }

    return formFileUrlFromFileArray(originalFileArray);
  }

  const resizedFile = resizedFileArray[0];
  const originalFile = originalFileArray[0];

  if (originalFile.fileName !== resizedFile.originalFileName) {
    if (dontShowOriginal) {
      return null;
    }

    return formFileUrlFromFileArray(originalFileArray);
  }

  return formFileUrlFromDbFile(resizedFile);
}
